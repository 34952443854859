<template>
  <div>
    <div class="md-layout md-size-60 md-alignment-center">
      <div class="md-layout-item md-size-60 md-small-size-90">
        <div class="container-search">
          <div class="gcse-searchresults-only"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoogleSearchPage',
  mounted() {
    const cxId = 'd2537778bef9c7aff'; // Replace with the desired cx value
    const script = document.createElement('script');
    script.src = `https://cse.google.com/cse.js?cx=${cxId}`;
    script.async = true;
    document.head.appendChild(script);
  },
};
</script>
